import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import DisplayWordingNbGuest from "./../../utils/DisplayWordingNbGuest";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: props.display,
    };
    this.handleCick = this.handleCick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.display !== this.state.display) {
      this.setState({
        display: nextProps.display,
      });
    }
  }
  strToDate(date) {
    if (date === "Date") {
      return "Date";
    }
    return moment(date).format("DD-MM-YYYY").toString();
  }

  handleCick(e) {
    e.preventDefault();
  }


  render() {
    const { menu } = this.props;
 
    const menuSteps = [
      {
        stepName: "day",
        circle: "date",
        defaultMessage:
         <FormattedMessage
          id="menu.client-date"
          defaultMessage="Jour"
        />,
        redirectionPath:"/date",
        stepCompleted: moment(menu.day).format("DD-MM-YYYY").toString()
      },
      {
        stepName: "nbGuest",
        circle: "diners",
        defaultMessage:
        <FormattedMessage
        id="menu.client-diner"
        defaultMessage="Couverts"
      />,
        redirectionPath:"/couverts",
        stepCompleted: DisplayWordingNbGuest(menu.nbGuest)
      },
      {
        stepName: "time",
        circle: "time",
        defaultMessage:
         <FormattedMessage
          id="menu.client-time"
          defaultMessage="Heure"
        />,
        redirectionPath:"/heure",
        stepCompleted: menu.time
      },
      {
        stepName: "contact",
        circle: "contact",
        defaultMessage:
        <FormattedMessage
        id="menu.client-contact"
        defaultMessage="Coordonnées"
      />,
        redirectionPath:"/contact",
        stepCompleted: menu.contact
      },
      {
        stepName: "send",
        circle: "validation",
        defaultMessage:
        <FormattedMessage
                    id="menu.client-send"
                    defaultMessage="Confirmer"
                  />,
        redirectionPath:"/legalInformation",
        stepCompleted: menu.send
      },
    ]

    const circle = (name) => {
      return (
        <div className="icon-menu">
          <span className="circle" id={name} />
        </div>
      );
    };

    const getPreviousStepName = (i) => {
      if (i < 1) {
        return
      } else {
      return menuSteps[i-1].stepName
      }
    }

    return (
      <nav className={!!this.props.display ? "" : "hide"}>
        <ul id="menu">
          {menuSteps.map((step, i) =>
          <li key={i}>
            {i > 0 && !menu[getPreviousStepName(i)] ?
            <a>
              {circle(step.circle)}
              <p>
                {step.defaultMessage}
              </p>
            </a> :
            <NavLink
              activeClassName={"arrow-right active"}
              to={step.redirectionPath}
              className={`${!!this.props.active[step.stepName || "legalInformation"] ? "stay-active" : ""}`}
            >
              {circle(step.circle)}
              <p>
                {menu[step.stepName] ?
                step.stepCompleted :
                step.defaultMessage}
              </p>
              </NavLink>}
          </li>
          )}
        </ul>
      </nav>
    );
  }
  static defaultProps = {
    menu: {
      day: "",
      time: "",
      diner: "",
      contact: "",
      send: "",
    },
    active: {
      day: false,
      time: false,
      diner: false,
      contact: false,
      legalInformation: false,
    },
    restaurantId: "",
    display: true,
  };
}

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  active: PropTypes.object.isRequired,
  restaurantId: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
};

export default Menu;
