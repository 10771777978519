import LogRocket from 'logrocket';
import config from '../config';

export function initLogRocket() {
  if (config.env !== 'local' || config.forceLogRocket) {
    LogRocket.init(config.LOGROCKET_PROJECT, {
      network: {
        requestSanitizer: (request) => {
          // scrub header value from request
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = '';
          }
          if (request.headers['refreshToken']) {
            request.headers['refreshToken'] = '';
          }

          // make sure you return the modified request
          return request;
        },
        responseSanitizer: (response) => {
          if (response.url.includes('auth/init')) {
            response.body = null;
          }
          if (response.url.includes('auth/access-token')) {
            response.body = null;
          }
          return response;
        },
      },
    });
  }
}

export function startLogRocketSession(customerId, data = {}) {
  if (config.env !== 'local' || config.forceLogRocket) {
    const payload = { env: config.env, ...data };
    // Log user session
    LogRocket.identify(customerId, payload);
  }
}