import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-phone-number-input/style.css';
import {
	formatPhoneNumber,
	isValidPhoneNumber,
} from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';
import es from 'react-phone-number-input/locale/es.json';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInput from 'react-phone-number-input';
import { recoverNavLang } from '../../utils/recoverNavLanguage';
import { AdminManager } from '../../utils/function';

class Contact extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			nameRestaurant: props.nameRestaurant,
			gender: '',
			lastname: '',
			firstname: '',
			phone: '',
			nationalPhone: '',
			email: '',
			society: '',
			information: '',
			hasError: false,
			isValidated: false,
			errors: '',
			isValidPhoneNumber: false,
		};
		this.form = React.createRef();
	}

	MANDATORY_FIELDS = AdminManager.isAdmin()
		? ['lastname']
		: ['gender', 'lastname', 'firstname', 'phone', 'email'];

	componentDidMount() {
		this.hydrateStateWithLocalStorage();

		if (this.state.errors !== null || '') {
			this.setState({
				errors: '',
			});
		}

		if (!this.state.isValidated && window.localStorage.phone) {
			this.setState({
				isValidPhoneNumber: isValidPhoneNumber(window.localStorage.phone),
			});
		}
	}

	/**
	 * check if all required input is not empty
	 */
	isValidated = () => {
		const { gender, lastname, firstname, phone, email, isValidPhoneNumber } =
			this.state;
		if (
			(AdminManager.isAdmin() && lastname) ||
			(gender &&
				lastname &&
				firstname &&
				phone &&
				email &&
				(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email) &&
				!!isValidPhoneNumber)
		) {
			// problem with autofill chorme (not reportValidity to true)
			/*  if (!!this.form.current.reportValidity()) {
				this.setState({
					isValidated: true
				});
			} */

			this.setState({
				isValidated: true,
			});
		} else {
			this.setState({
				isValidated: false,
			});
		}
	};

	componentWillReceiveProps() {
		this.hydrateStateWithLocalStorage();
	}

	componentDidCatch(err) {
		this.setState({ errors: err.message });
	}

	handleInputChange = e => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name]: value }, () => {
			const valide = this.isValidated();
			return valide ? window.localStorage.setItem(name, value) : '';
		});
	};

	/**
	 * check if phone is valid
	 */
	isValidatedPhone = phone => {
		if (phone && !!isValidPhoneNumber(phone)) {
			this.setState(
				{
					isValidPhoneNumber: isValidPhoneNumber(phone),
					phone: phone,
					nationalPhone: formatPhoneNumber(phone),
				},
				() => {
					window.localStorage.setItem(
						'nationalPhone',
						phone ? formatPhoneNumber(phone) : phone
					);
					this.isValidated();
				}
			);
		} else {
			this.setState(
				{
					phone: phone,
					isValidPhoneNumber: false,
					nationalPhone: phone ? formatPhoneNumber(phone) : phone,
				},
				() => {
					this.isValidated();
				}
			);
		}
	};

	handleChangeGender = (e, value) => {
		e.preventDefault();
		this.setState(
			{
				gender: value,
			},
			() => {
				this.isValidated();
			}
		);
		window.localStorage.setItem('gender', value);
	};

	hydrateStateWithLocalStorage() {
		for (let key in this.state) {
			if (window.localStorage.hasOwnProperty(key)) {
				let value = window.localStorage.getItem(key);
				try {
					value = JSON.parse(value);
					this.setState({ [key]: value }, () => {
						this.isValidated();
					});
				} catch (e) {
					this.setState({ [key]: value });
				}
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault();

		const { lastname, firstname } = this.state;
		this.props.onUpdate(`${firstname} ${lastname}`);

		//add local storage
		window.localStorage.setItem('firstname', this.state.firstname);
		window.localStorage.setItem('lastname', this.state.lastname);
		window.localStorage.setItem('email', this.state.email);
		window.localStorage.setItem('phone', this.state.phone);
		window.localStorage.setItem('gender', this.state.gender);
		window.localStorage.setItem('society', this.state.society);
		this.context.router.history.push('/legalInformation');
	};

	render() {
		const props = this.props;
		const country = recoverNavLang();
		const lang = window.navigator.language.slice(0,2);
		const labels = lang === 'en' ? en : lang === 'es' ? es : fr;
		const isAdmin = AdminManager.isAdmin();
		return (
			<React.Fragment>
				{this.state.errors ? <p>{this.state.errors} </p> : ''}
				<form
					{...this.props}
					noValidate
					onSubmit={e => this.handleSubmit(e)}
					id="contact"
					ref={this.form}>
					<div className="bloc-label">
						<h4>
							<FormattedMessage id="contact.gender" defaultMessage="Civilité" />
							{isAdmin ? '' : '*'}
						</h4>
						<div className="gender">
							<button
								className={`${
									this.state.gender === 'Mr' ? 'is-blue' : 'is-blue-line'
								} ${this.state.gender || isAdmin ? '' : 'empty'}`}
								onClick={e => this.handleChangeGender(e, 'Mr')}>
								<FormattedMessage id="contact.Mr" defaultMessage="M.">
									{message => <span value="Mr">{message}</span>}
								</FormattedMessage>
							</button>
							<button
								className={`${
									this.state.gender === 'Mrs' ? 'is-blue' : 'is-blue-line'
								} ${this.state.gender || isAdmin ? '' : 'empty'}`}
								onClick={e => this.handleChangeGender(e, 'Mrs')}>
								<FormattedMessage id="contact.Mrs" defaultMessage="Mme">
									{message => <span value="Mrs">{message}</span>}
								</FormattedMessage>
							</button>
							<button
								className={`${
									this.state.gender === 'Miss' ? 'is-blue' : 'is-blue-line'
								} ${this.state.gender || isAdmin ? '' : 'empty'}`}
								onClick={e => this.handleChangeGender(e, 'Miss')}>
								<FormattedMessage id="contact.Miss" defaultMessage="Miss">
									{message => <span value="Miss">{message}</span>}
								</FormattedMessage>
							</button>
						</div>
					</div>
					<div className="bloc-label">
						<h4>
							<FormattedMessage id="common.firstname" defaultMessage="Prénom" />
							{isAdmin ? '' : '*'}
						</h4>
						<input
							className={`input ${this.state.firstname ? 'validation' : ''}`}
							name="firstname"
							value={this.state.firstname}
							onChange={this.handleInputChange}
							type="text"
							required={!isAdmin}
						/>
					</div>
					<div className="bloc-label">
						<h4>
							<FormattedMessage id="common.name" defaultMessage="Nom" />*
						</h4>
						<input
							className={`input ${this.state.lastname ? 'validation' : ''}`}
							name="lastname"
							onChange={this.handleInputChange}
							type="text"
							value={this.state.lastname}
							required
						/>
					</div>
					<div className="bloc-label">
						<h4>
							<FormattedMessage id="common.phone" defaultMessage="Téléphone" />
							{isAdmin ? '' : '*'}
						</h4>
						<PhoneInput
							className={`input-phone ${
								this.state.phone
									? !!this.state.isValidPhoneNumber
										? 'validation'
										: 'error-phone'
									: isAdmin
									? ''
									: 'error-phone'
							}`}
							defaultCountry={country}
							labels={labels}
							placeholder="00 00 00 00 00"
							value={this.state.phone}
							onChange={phone => this.isValidatedPhone(phone)}
							flagsPath="https://flagicons.lipis.dev/flags/4x3/"
						/>
					</div>
					<div className="bloc-label">
						<h4>
							<FormattedMessage id="common.mail" defaultMessage="Email" />
							{isAdmin ? '' : '*'}
						</h4>
						<FormattedMessage
							id="contact.placeholderMail"
							defaultMessage="exemple@mail.com">
							{msg => (
								<input
									className={`input ${this.state.email ? 'validation' : ''}`}
									type="email"
									placeholder={msg}
									name="email"
									value={this.state.email}
									onChange={this.handleInputChange}
									pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
									id="email"
									required={!isAdmin}
								/>
							)}
						</FormattedMessage>
					</div>

					<div className="bloc-label">
						<h4>
							<FormattedMessage id="common.society" defaultMessage="Société" />
						</h4>
						<input
							className={`input ${this.state.society ? 'validation' : ''}`}
							type="text"
							placeholder=""
							name="society"
							onChange={this.handleInputChange}
							value={this.state.society}
						/>
					</div>
					<div className="submit-form">
						<button
							onClick={this.isValidated}
							className={`is-red ${
								!!this.state.isValidated ? 'isValidated' : ''
							}`}>
							<FormattedMessage id="common.validate" defaultMessage="Valider" />
						</button>
					</div>
				</form>
			</React.Fragment>
		);
	}

	static defaultProps = {
		nameRestaurant: 0,
	};
}

Contact.propTypes = {
	nameRestaurant: PropTypes.string.isRequired,
};

export default Contact;
