
const host = window.location.hostname;
const env = host === 'localhost' ? 'local' : (host === 'reservation.laddit.io' ? 'dev' : 'prod');
const forceLogRocket = false;
const LOGROCKET_PROJECT = 'q2xmea/reservation-ohctg';

const config = {
  env,
  forceLogRocket,
  LOGROCKET_PROJECT,
};

export default config;