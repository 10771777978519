import React, { Component } from 'react';
import Day from './Day';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const moment = extendMoment(Moment);

class CalendarUi extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    const currentWeek = moment()
      .startOf('isoWeek')
      .isoWeekday(1);
    this.state = {
      daysCount: 7,
      selectedDays: [],
      startDay: props.isMobile ? moment(new Date()) : currentWeek,
      nextDay: false,
      prevDay: false,
      openingHours: props.openingHours,
      format: 'YYYY-MM-DD',
      unavailables: false,
      closeDay: props.closeDay,
      beforeToday: false,
      listClosingDays: [],
      menuDay: props.menuDay,
      isMobile: props.isMobile,
      disableToday: props.disableToday,
      displayAllPeriod: props.displayAllPeriod,
      disablePeriod: props.disablePeriod,
      validationDelay: props.validationDelay
    };
  }

  componentWillReceiveProps(nextProps) {
    /*  console.log(nextProps);
    if (nextProps.disableToday !== this.state.disableToday) {
      let date = this.state.unavailables || [];
      if (nextProps.disableToday) {
        date.push(new moment().format('YYYY-MM-DD'));
      }
      this.setState({
        disableToday: nextProps.disableToday,
        unavailables: date
      });
    } */
  }

  componentDidMount() {
    const {
      closingDays,
      menuDay,
      disableAppPeriod,
      disableApp,
      openingHours
    } = this.props;
    let state = {
      ...this.state
    };
    let dates = [];
    // disabled closing days
    if (closingDays) {
      closingDays.map(range => {
        let startRange = moment(range.day1, 'YYYY-MM-DD').subtract(1, 'days');
        let endRange = moment(range.day2, 'YYYY-MM-DD').add(1, 'days');
        //push list of all days in unvablables
        while (startRange.add(1, 'days').diff(endRange) < 0) {
          dates.push(moment(startRange).format('YYYY-MM-DD'));
        }
      });

      if (!!this.props.disableToday) {
        dates.push(new moment().format('YYYY-MM-DD'));
      }
    }
    if (!!this.props.disableTomorrow) {
      dates.push(new moment().add('1', 'days'));
    }

    // if disableApp + disableApp with period + opening hour of disable app has only 1 period -> add to closingDays
    if (
      disableApp &&
      disableAppPeriod &&
      disableAppPeriod.date &&
      disableAppPeriod.period
    ) {
      const disableAppDate = disableAppPeriod.date;
      const selectedDateName = moment(disableAppDate, 'YYYY-MM-DD')
        .format('dddd')
        .toLowerCase();
      if (openingHours[selectedDateName].length < 2) {
        dates.push(disableAppDate);
      }
    }

    this.setState({
      unavailables: dates,
      listClosingDays: state.listClosingDays
    });

    /**
     * if one date is already selected, display the current week of the selected date
     */
    if (menuDay) {
      const day = moment(menuDay).format('YYYY-MM-DD');
      const currentWeek = moment(day)
        .startOf('isoWeek')
        .isoWeekday(1);
      this.setState({
        selectedDays: [menuDay],
        startDay: currentWeek
      });
    }
  }

  daySelect = day => {
    this.setState({
      selectedDays: [day]
    });
    this.props.onUpdate(day);
    if (
      !this.state.isMobile ||
      (!!this.state.isMobile && !this.state.displayAllPeriod)
    ) {
      //
      // function donner la date
      if (this.props.isMobile) {
        this.props.updateNextDate(moment(day).format('YYYY-MM-DD'));
        this.props.closePopup();
      } else {
        this.context.router.history.push('/couverts');
      }
    } else {
      this.props.closePopup();
    }
  };

  checkSelectedDay(day) {
    let selected = false;
    if (this.state.selectedDays.length > 0) {
      this.state.selectedDays.forEach((d, i) => {
        if (day.isSame(d, 'day')) {
          selected = true;
        }
      });
    }
    return selected;
  }

  /**
   * handle unavailables days
   * @param {} day
   */
  checkUnavailables(day) {
    const {
      closeDay,
      unavailables,
      beforeToday,
      openingHours,
      validationDelay
    } = this.state;
    let unavailable = false;
    const nameOfDay = moment(day, 'YYYY-MM-DD')
      .format('dddd')
      .toLowerCase();

    // if is in closing dates
    if (unavailables) {
      unavailables.map((d, i) => {
        if (day.isSame(moment(d), 'day')) {
          unavailable = true;
        }
      });
    }

    // before today
    if (!beforeToday) {
      const dayTime = day.format('dddd').toLowerCase();
      const dayOpeningHours = openingHours[dayTime];
      const lastHourAvailable =
        dayOpeningHours && dayOpeningHours.length
          ? 
            dayOpeningHours[dayOpeningHours.length - 1].time2

          : '';

      if (lastHourAvailable) {
        const DateMonthDay = day.format('YYYY-MM-DD');
        const fullDate = moment(`${DateMonthDay} ${lastHourAvailable}`);
        const nowWithDelay = moment().add(validationDelay, 'm');

        if (nowWithDelay.isAfter(fullDate)) {
          unavailable = true;
        }
      } else {
        unavailable = true;
      }
    }

    // if empty time in opening hours (name of day)
    if (closeDay) {
      closeDay.map(nameDate => {
        if (nameDate.toLowerCase() === nameOfDay) {
          unavailable = true;
        }
      });
    }

    return unavailable;
  }

  removeTime = (time, timeLess) => {
    const isOldSchedule = this.checkOldSchedule();
    if (isOldSchedule) {
      window.localStorage.removeItem('info');
      window.location.reload();
      return;
    }

    const timeSplit = time.split(':');
    const [hours, minutes] = timeSplit;
    return moment()
      .hour(hours)
      .minutes(minutes)
      .subtract(timeLess, 'm')
      .format('HH:mm');
  };

  checkOldSchedule = () => {
    const info = window.localStorage.getItem('info');
    if (info) {
      const parseInfo = JSON.parse(info);
      const isOld = Object.keys(parseInfo.openingHours).some(key => {
        if (parseInfo.openingHours[key].length) {
          const openingDate = Object.keys(parseInfo.openingHours[key][0]);
          return openingDate[0] === 'morning' || openingDate[0] === 'evening';
        }
        return false;
      });
      return isOld;
    } else {
      return false;
    }
  };

  /**
   * display previous week
   */
  handlePrevWeek = e => {
    e.preventDefault();
    const { startDay, selectedDays, daysCount } = this.state;
    this.setState(
      {
        startDay: startDay.clone().add(-daysCount, 'days')
      },
      () => {
        if (this.props.onPrevClick) {
          this.props.onPrevClick(startDay, selectedDays);
        }
      }
    );
  };

  /**
   * display next week
   */
  handleNextWeek = e => {
    e.preventDefault();
    const { startDay, selectedDays, daysCount } = this.state;
    this.setState(
      {
        startDay: startDay.clone().add(daysCount, 'days')
      }
      /*  () => {
        if (this.props.onNextClick) {
          this.props.onNextClick(startDay, selectedDays);
        }
      } */
    );
  };

  handleMonth = (e, action) => {
    e.preventDefault();

    let startDay;
    if (action === 'remove') {
      startDay = moment(this.state.startDay).subtract(1, 'months');
    } else {
      startDay = moment(this.state.startDay).add(1, 'months');
    }
    const startOfMonth = moment(startDay).startOf('month');

    this.setState({
      startDay: startOfMonth
    });
  };

  /**
   * render each days of week
   * @param {} weekdays
   */
  renderView(weekdays) {
    const dayComps = weekdays.map((day, i) => (
      <Day
        {...this.props}
        day={day}
        key={i}
        click={e => this.daySelect(e, day)}
        selected={this.checkSelectedDay(day)}
        unavailable={this.checkUnavailables(day)}
        userDay={this.props.userDay}
      />
    ));
    return <div id="calendar-bloc">{dayComps}</div>;
  }

  render() {
    const startDay = moment(this.state.startDay);
    const weekdays = [];

    for (let index = 0; weekdays.length < this.state.daysCount; index++) {
      let day = startDay.clone().add(index, 'days');
      weekdays.push(day);
    }
    const nameMonth = moment(weekdays[0])
      .format('MMMM')
      .toLowerCase();

    return (
      <div className="container pickerDate">
        <div id="month">
          <div
            onClick={e => this.handleMonth(e, 'remove')}
            className="cta-prev-month"
          />
          <p>
            {
              <FormattedMessage
                id={`month.${nameMonth}`}
                defaultMessage={nameMonth}
              />
            }{' '}
            {moment(weekdays[0]).format('YYYY')}
          </p>
          <div
            onClick={e => this.handleMonth(e, 'add')}
            className="cta-next-month"
          />
        </div>
        <div id="days">
          <div
            onClick={this.handlePrevWeek}
            className={`cta-prev ${window.chrome ? 'transformArrow' : ''}`}
          />
          {this.renderView(weekdays)}
          <div
            onClick={this.handleNextWeek}
            className={`cta-next ${window.chrome ? 'transformArrow' : ''}`}
          />
        </div>
      </div>
    );
  }
  static defaultProps = {
    openingHours: [],
    validationDelay: 0,
    isMobile: false,
    displayAllPeriod: true,
    disableToday: false,
    disableTomorrow: false
  };
}

CalendarUi.propTypes = {
  val: PropTypes.string,
  closingDays: PropTypes.array,
  openingHours: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  menuDay: PropTypes.string,
  closeDay: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  displayAllPeriod: PropTypes.bool.isRequired,
  disableToday: PropTypes.bool.isRequired,
  disableTomorrow: PropTypes.bool.isRequired,
  userDay: PropTypes.string,
  closePopup: PropTypes.func,
  disablePeriod: PropTypes.object,
  updateNextDate: PropTypes.func
};

export default CalendarUi;
