import React from 'react';
import classNames from 'classnames';

function Slot({ i, slot, selectedHour, frozenSlots, guests, onChange }) {
  if (!slot.length) {
    return null;
  }

  return (
    <div className="timeslots__content">
      <ol className="timeslots__list">
        {slot.map((hour, j) => {
          // Compute disabled flag
          const f = frozenSlots[hour];
          const isFrozen = f ? f.freeze : false;
          const isForbidden = f?.forbidden ? f.forbidden.includes(guests) : false;
          const isFullCapacity = f?.remaining ? f.remaining < guests : false;
          const disabled = isFrozen || isForbidden || isFullCapacity;

          // Compute classnames
          const className = 'timeslots__list__item';
          const classes = classNames(className, {
            [`${className}--active`]: selectedHour === hour,
            [`${className}--disabled`]: disabled,
          });

          return (
            <li key={`list-item-${i}-${j}`} className={classes}>
              <input
                id={`radio-${i}-${j}`}
                type="radio"
                name="time"
                onChange={(e) => onChange(e.target.value)}
                value={hour}
                disabled={disabled}
              />
              <label htmlFor={`radio-${i}-${j}`}>{hour}</label>
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default function RadioTimePicker(props) {
  const { data, selectedHour, onChange, frozenSlots, guests } = props;

  return (
    <div className="timeslots">
      {data.map((slot, i) => (
        <Slot
          key={`list-item-${i}`}
          i={i}
          slot={slot}
          selectedHour={selectedHour}
          frozenSlots={frozenSlots}
          onChange={onChange}
          guests={guests}
        />
      ))}
    </div>
  );
}
