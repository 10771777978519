import React, { Component } from 'react';

import { recoverNavLang } from '../../utils/recoverNavLanguage';
import fr from 'react-phone-number-input/locale/fr.json';
import es from 'react-phone-number-input/locale/es.json';
import en from 'react-phone-number-input/locale/en.json';
import { isValidPhoneNumber } from 'react-phone-number-input';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-number-input';
import { FormattedMessage } from 'react-intl';
import CallApi from './../../utils/CallApi';
import 'react-phone-number-input/style.css';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import jstz from 'jstimezonedetect';

var tz = jstz.determine();
const borwserTz = tz.name();

class Informations extends Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      restaurantId: props.restaurantId,
      contact: {
        name: props.contact.name,
        email: props.contact.email,
        slogan: props.contact.slogan,
        streetNumber: props.contact.streetNumber,
        street: props.contact.street,
        additionalAddress: props.contact.additionalAddress,
        zipcode: props.contact.zipcode,
        city: props.contact.city,
        phone: props.contact.phone,
        country: props.contact.country,
        timezone: props.contact.timezone || borwserTz
      },
      listTimezone: [],
      options: this.options,
      displaySuccessMessage: false,
      openingHours: props.openingHours,
      success: false,
      isValidated: false,
      handleSelect: false,
      isValidPhoneNumber: false
    };

    this.form = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.openingHours !== prevState.openingHours ||
      nextProps.contact !== prevState.contact ||
      nextProps.timezone !== prevState.timezone
    ) {
      return {
        openingHours: nextProps.openingHours,
        contact: {
          name: prevState.contact.name,
          email: prevState.contact.email,
          slogan: prevState.contact.slogan,
          streetNumber: prevState.contact.streetNumber,
          street: prevState.contact.street,
          additionalAddress: prevState.contact.additionalAddress,
          zipcode: prevState.contact.zipcode,
          city: prevState.contact.city,
          phone: prevState.contact.phone,
          country: prevState.contact.country,
          timezone: prevState.contact.timezone
        }
      };
    }
  }

  async componentDidMount() {
    this.isValidated();

    if (!this.state.isValidated && this.state.contact.phone) {
      this.setState(
        {
          isValidPhoneNumber: isValidPhoneNumber(this.state.contact.phone)
        },
        () => {
          this.isValidated();
        }
      );
    }

    if (this.state.listTimezone.length === 0) {
      try {
        const listTimezone = await this.listTimezone();
        const country = this.state.timezone
          ? this.state.timezone.split('/')
          : borwserTz.split('/');
        const timezone = listTimezone.filter(timezone =>
          timezone.name.contains(country)
        );
        this.setState({
          timezone
        });
      } catch (e) {}
    }
  }

  componentDidUpdate() {
    if (this.state.success) {
      setTimeout(() => {
        const contact = {
          name: this.state.contact.name,
          slogan: this.state.contact.slogan,
          email: this.state.contact.email,
          streetNumber: this.state.contact.streetNumber,
          street: this.state.contact.street,
          additionalAddress: this.state.contact.additionalAddress,
          zipcode: this.state.contact.zipcode,
          city: this.state.contact.city,
          phone: this.state.contact.phone,
          country: this.state.contact.country,
          timezone: this.state.contact.timezone
        };
        this.props.onUpdateContact(contact);

        this.setState({
          success: false
        });
      }, 800);
    }
  }

  /**
   * check if all required input is not empty
   */
  isValidated = () => {
    const { name, zipcode, city, phone, email } = this.state.contact;
    const forbiddenEmail = email.includes('laddition.com');
    const checkEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      name &&
      zipcode &&
      city &&
      email &&
      !forbiddenEmail &&
      checkEmail.test(String(email).toLowerCase()) &&
      phone &&
      isValidPhoneNumber(phone)
    ) {
      this.setState({
        isValidated: true
      });
    } else {
      this.setState({
        isValidated: false
      });
    }
  };

  handleClick = async () => {
    this.isValidated()
    this.props.onUpdateRulesStatus()
  }

  handleInputChange = e => {
    const { target } = e;
    const { value, name } = target;
    const { ...state } = this.state;
    state.contact[name] = value;

    this.setState(state, () => {
      this.isValidated();
    });
  };

  handleSelectChange = value => {
    const { ...state } = this.state;
    state.contact.country = {
      code: value.value,
      label: value.label
    };
    this.setState(state, () => {
      this.isValidated();
    });
  };

  // problem with correct selected value
  handleClickSelect = e => {
    e.preventDefault();
    this.setState({
      handleSelect: !this.state.handleSelect
    });
  };

  /**
   * check if phone is valid
   */
  isValidatedPhone = phone => {
    const { ...state } = this.state;
    state.contact.phone = phone;

    if (phone && !!isValidPhoneNumber(phone)) {
      //state.isValidPhoneNumber = true;
      this.setState(state, () => {
        this.isValidated();
      });
    }
    this.isValidated();

    window.localStorage.setItem('phone', phone);
  };

  /**
   * Update information of restaurant
   * @param {*} e
   */
  handleSubmit = async e => {
    e.preventDefault();
    const { contact } = this.state;

    const infoAndAdresss = {
      address: {
        streetNumber: contact.streetNumber,
        street: contact.street,
        additionalAddress: contact.additionalAddress,
        zipcode: contact.zipcode,
        city: contact.city,
        country: contact.country
      },
      info: {
        name: contact.name,
        emailContact: contact.email,
        slogan: contact.slogan,
        phone: contact.phone,
        timezone: contact.timezone
      }
    };

    try {
      const res = await CallApi(
        'put',
        'restaurants',
        this.state.restaurantId,
        infoAndAdresss,
        this.props.token
      );

      if (res[0]) {
        this.setState(
          {
            success: true
          },
          () => {
            // if opening hours is empty, redirect to hours view
            if (!this.findOpeninghours()) {
              this.props.history.push('/horaires');
            }
            this.props.enqueueSnackbar(
              <FormattedMessage
                id="common.success"
                defaultMessage="Vos changements ont bien été pris en compte."
              />,
              { variant: 'success' }
            );
          }
        );
      } else {
        this.props.enqueueSnackbar(
          <FormattedMessage
            id="common.errorRequest"
            defaultMessage="Oups... nous rencontrons un problème avec l'enregistrement de vos données"
          />,
          { variant: 'error' }
        );
      }
    } catch (err) {
      this.props.enqueueSnackbar(
        <FormattedMessage
          id="common.errorRequest"
          defaultMessage="Oups... nous rencontrons un problème avec l'enregistrement de vos données"
        />,
        { variant: 'error' }
      );
    }
  };

  findOpeninghours = () => {
    const openingHours = this.state.openingHours;
    for (let key in openingHours) {
      if (openingHours[key].length > 0) {
        return true;
      }
    }
    return false;
  };

  handleTimezone = e => {
    e.preventDefault();
    const { ...contact } = this.state.contact;
    contact.timezone = e.target.value;
    this.setState({ contact });
  };

  listTimezone = () => {
    const listTimezone = [];
    const selectorOptions = moment.tz
      .names()
      .reduce((memo, tz) => {
        memo.push({
          name: tz,
          offset: moment.tz(tz).utcOffset()
        });

        return memo;
      }, [])
      .sort((a, b) => {
        return a.offset - b.offset;
      })
      .reduce((memo, tz) => {
        const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';
        listTimezone.push({
          name: tz.name,
          timezone: timezone
        });
      }, '');

    this.setState({
      listTimezone
    });
  };

  displayTimezone = () => {
    return this.state.listTimezone.map((timezone, i) => {
      return (
        <option value={timezone.name} key={`timezone-${i}`}>
          {timezone.name} ({timezone.timezone})
        </option>
      );
    });
  };

  render() {
    const forbiddenEmail = this.state.contact.email.includes('laddition.com')
    const country = recoverNavLang();
		const lang = window.navigator.language.slice(0,2);
		const phoneLabels = lang === 'en' ? en : lang === 'es' ? es : fr;
    return (
      <React.Fragment>
        <form
          onSubmit={e => this.handleSubmit(e)}
          noValidate
          className="pure-form pure-form-stacked"
          id="informations"
          ref={this.form}
        >
          <div id="error-server" />
          <div className="pure-g">
            <div className="pure-u-1 pure-u-lg-1-3 space-block">
              <h3>
                <FormattedMessage
                  id="info.restaurant"
                  defaultMessage="Fiche établissement"
                />
              </h3>

              <label className="label">
                <FormattedMessage
                  id="info.name"
                  default="Nom d'établissement"
                />
                *
              </label>
              <input
                className={`input ${
                  this.state.contact.name ? 'validation' : ''
                }`}
                name="name"
                onChange={this.handleInputChange}
                type="text"
                autoComplete="name"
                value={this.state.contact.name || ''}
                required
              />
              <label className="label">
                <FormattedMessage id="common.slogan" defaultMessage="Slogan" />
              </label>
              <input
                className="input"
                name="slogan"
                autoComplete="name"
                onChange={this.handleInputChange}
                type="text"
                value={this.state.contact.slogan || ''}
              />

              <label className="label">
                <FormattedMessage
                  id="common.phone"
                  defaultMessage="Téléphone"
                />
                *
              </label>
              <PhoneInput
                className={`input-phone ${
                  this.state.contact.phone &&
                  !!isValidPhoneNumber(this.state.contact.phone)
                    ? ''
                    : 'error-phone'
                }`}
                defaultCountry={country}
                labels={phoneLabels}
                placeholder="00 00 00 00 00"
                value={this.state.contact.phone}
                onChange={phone => this.isValidatedPhone(phone)}
                flagsPath='https://flagicons.lipis.dev/flags/4x3/'
                required
              />
              <label className="label">
                <FormattedMessage
                  id="common.emailContact"
                  defaultMessage="Email de contact"
                />
              </label>
              <input
                className={forbiddenEmail? "error-mail" : "input"}
                name="email"
                autoComplete="email"
                type="email"
                onChange={this.handleInputChange}
                value={this.state.contact.email}
                required
                //disabled
              />
              {forbiddenEmail &&
                <p className="error-form">
                  Le domaine laddition.com ne peut pas être utilisé ici.
                </p>
              }
              <label className="label">
                <FormattedMessage
                  id="common.timezone"
                  defaultMessage="timezone"
                />
                *
              </label>
              <select
                className="input timezone"
                value={this.state.contact.timezone}
                onChange={this.handleTimezone}
                required
              >
                {this.displayTimezone()}
              </select>
            </div>

            <div className="pure-u-1 pure-u-lg-1-3">
              <h3>
                <FormattedMessage
                  id="common.address"
                  defaultMessage="Adresse"
                />
              </h3>

              <label className="label">
                <FormattedMessage id="common.country" defaultMessage="Pays" />
              </label>
              <input
                className="input"
                name="country"
                onChange={this.handleInputChange}
                type="text"
                autoComplete="country"
                value={this.state.contact.country || ''}
              />
              <label className="label">
                <FormattedMessage
                  id="common.zipcode"
                  defaultMessage="Code postal"
                />
                *
              </label>
              <input
                className="input"
                name="zipcode"
                onChange={this.handleInputChange}
                type="text"
                pattern="[0-9]{4,10}"
                autoComplete="postal-code"
                value={this.state.contact.zipcode || ''}
                required
              />
              <label className="label">
                <FormattedMessage id="common.city" defaultMessage="Ville" />*
              </label>
              <input
                className="input"
                name="city"
                onChange={this.handleInputChange}
                type="text"
                autoComplete="address-level2"
                value={this.state.contact.city || ''}
                required
              />
              <div className="pure-g input-street">
                <div className="pure-u-1 pure-u-md-3-24">
                  <label className="label">
                    <FormattedMessage
                      id="common.streetNumber"
                      defaultMessage="N°"
                    />
                  </label>
                  <input
                    className="input"
                    name="streetNumber"
                    onChange={this.handleInputChange}
                    type="text"
                    //pattern="[0-9]"
                    autoComplete="address-line1"
                    value={this.state.contact.streetNumber || ''}
                  />
                </div>
                <div className="pure-u-1 pure-u-md-19-24">
                  <label className="label">
                    <FormattedMessage id="common.street" defaultMessage="Rue" />
                  </label>
                  <input
                    className="input"
                    name="street"
                    onChange={this.handleInputChange}
                    type="text"
                    autoComplete="address-line1"
                    value={this.state.contact.street || ''}
                  />
                </div>
              </div>
              <label className="label">
                <FormattedMessage
                  id="common.detail"
                  defaultMessage="Complément"
                />
              </label>

              <input
                className="input"
                name="additionalAddress"
                onChange={this.handleInputChange}
                type="text"
                autoComplete="address-line2"
                value={this.state.contact.additionalAddress || ''}
              />
              <div className="submit">
                <button
                  onClick={this.handleClick}
                  className={`is-red ${
                    !!this.state.isValidated ? 'isValidated' : ''
                  }`}
                >
                  <FormattedMessage
                    id="common.validate"
                    defaultMessage="valider"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="circle-blue big" />
          <div className="circle-blue tiny" />
        </form>
      </React.Fragment>
    );
  }
  static defaultProps = {
    token: '',
    restaurantId: 1,
    contact: {
      name: '',
      email: '',
      slogan: '',
      streetNumber: '',
      street: '',
      additionalAddress: '',
      zipcode: '',
      city: '',
      phone: '',
      country: {
        code: '',
        label: ''
      },
      timezone: borwserTz
    }
  };
}

Informations.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  openingHours: PropTypes.object,
  onUpdateContact: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired
};

export default withSnackbar(Informations);
