import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import 'moment/locale/fr';
import moment from 'moment';
import 'moment/min/moment-with-locales';
import messages_fr from './translations/fr.json';
import messages_es from './translations/es.json';

import { unregister } from './registerServiceWorker';
import App from './containers/App';
import 'purecss/build/pure-min.css';

//if (window.location.hostname === 'tb.dev.laddition.com') {
//LogRocket.init('q2xmea/la-reservation');
//}

const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(
  Function.call,
  Object.prototype.propertyIsEnumerable
);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

if (!Object.values) {
  Object.values = function values(O) {
    return reduce(
      keys(O),
      (v, k) =>
        concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []),
      []
    );
  };
}

if (!Object.entries) {
  Object.entries = function entries(O) {
    return reduce(
      keys(O),
      (e, k) =>
        concat(
          e,
          typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []
        ),
      []
    );
  };
}

const messagesList = {
  fr: messages_fr,
  es: messages_es
};

const language = navigator.language.split(/[-_]/)[0];

moment.locale('en');

const messages = messagesList.hasOwnProperty(language) ? messagesList[language] : messagesList.fr;

ReactDOM.render(
  <IntlProvider locale={language} messages={messages} textComponent="span">
    <App />
  </IntlProvider>,
  document.getElementById('root')
);

unregister();
