import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Header({ info, desktop }) {
  const {
    streetNumber,
    street,
    nameRestaurant,
    slogan,
    additionalAddress,
    zipcode,
    city,
    country,
  } = info;

  return (
    <header>
      <div id="book">
        <h1>
          <FormattedMessage
            id="common.title"
            defaultMessage="Réservez votre table"
          />
        </h1>
      </div>
      <div id="restaurant">
        <div className="info">
          <h2>{nameRestaurant}</h2>
          {desktop ? <h4 className="slogan">{slogan}</h4> : null}
          <p>
            {streetNumber ? streetNumber : ''}{' '}
            {street ? street : ''}{' '}
            {additionalAddress ? ` - ${additionalAddress}` : ''}
          </p>
          <p>
            {zipcode ? zipcode : ''}{' '}
            {city ? city : ''}
            {country ? ` - ${country}` : ''}
          </p>
        </div>
      </div>
    </header>
  );
};
