import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Nombre d'éléments à afficher par défaut (multiple de 4 et 6 pour l'affichage responsive)
const DEFAULT_DISPLAY = 12;

export default function Guests(props) {
  const hasManyGuests = props.maxGuests > DEFAULT_DISPLAY;
  const [showFullList, setShowFullList] = useState(!hasManyGuests);
  const isListTruncated = hasManyGuests && !showFullList;

  // Gestion du clic sur un élément
  function handleClick(guestCount) {
    props.onUpdate(guestCount);
    props.history.push('/heure');
  }

  // Composant pour afficher les boutons de nombre d'invités
  function GuestPicker() {
    const maxDisplay = isListTruncated ? DEFAULT_DISPLAY : props.maxGuests;
    const guestButtons = [];
    for (let i = 1; i <= maxDisplay; i++) {
      guestButtons.push(
        <button
          key={`guest-item-${i}`}
          onClick={() => handleClick(i)}
        >
          {i}
        </button>
      );
    }
    return guestButtons;
  }

  // Composant pour afficher le bouton qui montre tout !
  function ShowMore() {
    if (!isListTruncated) {
      return null;
    }
    return (
      <button
        className="show-more-button"
        onClick={() => setShowFullList(true)}
      >
        <FormattedMessage id="nbGuest.showMore" />
      </button>
    );
  }

  return (
    <div id="nbGuest">
      <h3>
        <FormattedMessage id="nbGuest.titleDesktop" />
      </h3>
      <div className="pick-guests">
        <GuestPicker />
      </div>
      <ShowMore />
    </div>
  );
}